import { Injectable } from '@angular/core';

/** Словарь терминов */
const TERM_DCT: TermDict = {
    'role_ui': 'Пользовательская роль',
    'ui.ent.role_ui.lbl.nom': 'Пользовательская роль',
    'ui.ent.role_ui.lbl.nom.plural': 'Пользовательские роли',
    'ui.ent.role_ui.lbl.genitive': 'Пользовательской роли',
    'ui.ent.role_ui.lbl.genitive.plural': 'Пользовательских ролей',
    'ui.ent.role_ui.lbl.accusative': 'Пользовательскую роль',
    'ui.ent.role_ui.lbl.accusative.plural': 'Пользовательские роли',

    'ui.ent.organization_info.title': 'Реквизиты организации',
    'ui.ent.organization_info.lbl.nom': 'Реквизиты организации',
    'ui.ent.organization_info.lbl.genitive': 'Реквизитов организации',
    'ui.ent.organization_info.lbl.genitive.plural': 'Реквизитов организации',

    'ui.page.database.title': 'Данные',
    'ui.page.database.icon': 'cube',
    'ui.page.databoard.title': 'Мои данные',
    'ui.page.databoard.icon': 'folder',
    'ui.page.desk-fo.title': 'Рабочий стол ФО',
    'ui.page.desk-fo.icon': 'apps',
    'ui.page.desk.title': 'Рабочий стол',
    'ui.page.invoices-oo.title': 'Счёт-фактуры ОО',
    'ui.page.invoices-oo.icon': 'document',
    'ui.page.desk.icon': 'apps',
    'ui.page.map.title': 'Карта',
    'ui.page.map.icon': 'map',
    'ui.page.help.title': 'Помощь',
    'ui.page.help.icon': 'help',
    'ui.page.reports.title': 'Отчётность',
    'ui.page.reports.icon': 'reader',
    'ui.page.licenses.title': 'Лицензии',
    'ui.page.licenses.icon': 'ribbon',
    'ui.page.wasteplaces.title': 'Места накопления',
    'ui.page.wasteplaces.icon': 'trash',
    'ui.page.vehicles.title': 'Транспортные средства',
    'ui.page.vehicles.icon': 'bus',
    'ui.page.facilities.title': 'Объекты обращения',
    'ui.page.facilities.icon': 'business',
    'ui.page.organizations.title': 'Организации',
    'ui.page.organizations.icon': 'briefcase',
    'ui.page.summary.title': 'Сводка',
    'ui.page.summary.icon': 'pie-chart',
    'ui.page.bids.title': 'Заявки с отходообразователем',
    'ui.page.bids.icon': 'document',
    'ui.page.bids-ws.title': 'Заявки с отходообразователем',
    'ui.page.bids-ws.icon': 'document',
    'ui.page.modeling.title': 'Моделирование',
    'ui.page.modeling.icon': 'map',
    'ui.page.telemetry.title': 'Настройка телеметрии',
    'ui.page.telemetry.icon': 'radio',
    'ui.page.waste-conflicts.title': 'Конфликты',
    'ui.page.waste-conflicts.icon': 'alert-circle',
    'ui.page.orders-wastesource.title': 'Заказы на выгрузку',
    'ui.page.orders-wastesource.icon': 'document-outline',
    'ui.page.orders-recycling.title': 'Заказы на переработку',
    'ui.page.orders-recycling.icon': 'document-outline',
    'ui.page.orders-transportation.title': 'Заказы на транспортирование',
    'ui.page.orders-transportation.icon': 'document-outline',
    'ui.page.driver-tasks.icon': 'extension-puzzle',
    'ui.page.analytics.title': 'Аналитика',
    'ui.page.analytics.icon': 'bar-chart',
    'ui.page.datafiles.title': 'Федеральная схема',
    'ui.page.datafiles.icon': 'archive',
    'ui.page.recycle-reports.title': 'Акты об обращении с отходами',
    'ui.page.recycle-reports.icon': 'repeat-sharp',
    'ui.page.contracts-transportation.icon': 'document-text',
    'ui.page.contracts-waste-generator.icon': 'document-text',
    'ui.page.contracts-recycling.icon': 'document-text',
    'ui.page.contracts-complex-service.icon': 'document-text',
    'ui.page.responds-complex-service.icon': 'reader-outline',
    'ui.page.responds-recycling.icon': 'reader-outline',
    'ui.page.responds-transportation.icon': 'reader-outline',
    'ui.page.charges-complex-service.icon': 'albums',
    'ui.page.charges-recycling.icon': 'albums',
    'ui.page.charges-transportation.icon': 'albums',
    'ui.page.subcontracts-waste-generator.icon': 'document-text-outline',
    'ui.page.integration-1c.title': 'Управление интеграцией',
    'ui.page.mrpoa.title': 'Машиночитаемые доверенности',
    'ui.page.contactdetail.title': 'Контактные данные',
    'ui.page.contactdetail.icon': 'people-outline',
    'ui.ent.contact_detail.title': 'Контакты',
    'ui.page.eosdo_integration.title': 'Обмен с ЕОСДО',
    'ui.page.eosdo_integration.icon': 'shuffle-outline',
    'ui.page.waste_handover.title': 'Передача ОПВК',
    'ui.page.waste_handover.icon': 'document-text',
    'ui.ent.waste_handover.title': 'Прием ОПВК',
    'ui.ent.waste_handover_wg.title': 'Передача ОПВК',
    'ui.ent.waste_handover.icon': 'document-text',
    'ui.ent.waste_handover.lbl.nom': 'Передача ОПВК',
    'ui.ent.waste_handover.lbl.genitive': 'объектов передачи ОПВК',
    'ui.ent.waste_handover.lbl.genitive.plural': 'объектов передачи ОПВК',

    'ui.ent.wasteplace.title': 'Место накопления отходов',
    'ui.ent.vehicle.title': 'Сведения о транспортном средстве',
    'ui.ent.license.title': 'Лицензия оператора',
    'ui.ent.facility.title': 'Сведения об объекте обращения',
    'ui.ent.wastereport_operation.title': 'Сведения об операции с отходами',
    'ui.ent.organization.title': 'Сведения об организации',
    'ui.ent.bid.title': 'Заявка с отходообразователем',
    'ui.ent.waste_conflict.title': 'Конфликт',
    'ui.ent.order_waste_source.title': 'Заказ на выгрузку',
    'ui.ent.order_recycling.title': 'Заказ на переработку',
    'ui.ent.order_transportation.title': 'Заказ на транспортирование',
    'ui.ent.trip_report.title': 'Отчет по задаче',
    'ui.ent.integration_weighing.title': 'Факт взвешивания',
    'ui.ent.waste_container.title': 'Грузовое место для отходов',
    'ui.ent.recycle_report.title': 'Акт об обращении с отходами',
    'ui.ent.mrpoa.title': 'Машиночитаемая доверенность',
    'ui.ent.order_waste_source_invoice.title': 'Счет-фактура',
    'ui.ent.order_transportation_invoice.title': 'Счет-фактура',
    'ui.ent.order_recycling_invoice.title': 'Счет-фактура',

    // nominative - nom        кто? что?
    // genitive       НЕТ      кого? чего?
    // dative         ДАТЬ     кому? чему?
    // accusative     ВИЖУ     кого? что?
    // ablative       ДОВОЛЕН  кем? чем?
    // prepositional  ДУМАЮ    о ком? о чем?
    'ui.ent.wasteplace.lbl.nom': 'Место накопления',
    'ui.ent.wasteplace.lbl.nom.plural': 'Места накопления',
    'ui.ent.wasteplace.lbl.genitive': 'Места накопления',
    'ui.ent.wasteplace.lbl.genitive.plural': 'Мест накопления',
    'ui.ent.wasteplace.lbl.accusative': 'Место накопления',
    'ui.ent.wasteplace.lbl.accusative.plural': 'Места накопления',

    'ui.ent.license.lbl.nom': 'Лицензия',
    'ui.ent.license.lbl.nom.plural': 'Лицензии',
    'ui.ent.license.lbl.genitive': 'Лицензии',
    'ui.ent.license.lbl.genitive.plural': 'Лицензий',
    'ui.ent.license.lbl.accusative': 'Лицензию',
    'ui.ent.license.lbl.accusative.plural': 'Лицензии',

    'ui.ent.user.lbl.nom': 'Пользователь',
    'ui.ent.user.lbl.nom.plural': 'Пользователи',
    'ui.ent.user.lbl.genitive': 'Пользователя',
    'ui.ent.user.lbl.genitive.plural': 'Пользователей',
    'ui.ent.user.lbl.accusative': 'Пользователя',
    'ui.ent.user.lbl.accusative.plural': 'Пользователей',

    'ui.ent.vehicle.gnum': 'Госномер',
    'ui.ent.user.title': 'Пользователь',
    'ui.ent.user_profile.title': 'Пользователь',
    'ui.page.dashboard.title': 'Дашборд',
    'ui.page.dashboard.icon': 'document-text',
    'ui.page.invoices_wg.title': 'Счёт-фактуры ОО',
    'ui.page.invoices_wg.icon': 'document-text',

    'ui.page.support_ticket.title': 'Обращения отходообразователей',
    'ui.page.support_ticket_technical.title': 'Мои обращения',
    'ui.page.support_ticket_technical.icon': 'chatbubble-ellipses-outline',
    'ui.ent.support_ticket.title': 'Обращение',
    'ui.page.support_ticket.icon': 'chatbubble-ellipses-outline',

    'ui.page.notifications.title': 'Уведомления',
    'ui.page.notifications-tasks.title': 'Мои задачи',
    'ui.page.notifications.icon': 'notifications-outline',
    'ui.page.notifications-tasks.icon': 'create-outline',
    'ui.ent.wastereport_operation_correction.title': 'Корректирующая запись',
    'ui.ent.wastereport_operation_correction.icon': 'document-text',
    'ui.ent.stock_correction.title': 'Ввод остатков',
    'ui.ent.stock_correction.icon': 'document-text',
    'ui.page.off-system-info.title': 'Ввод внесистемной информации',
    'ui.page.off-system-info.icon': 'document-text',
    'ui.ent.off_system_payment.title': 'Внесистемная оплата оператора',
    'ui.ent.off_system_payment.icon': 'document-text',
    'ui.page.distribution.title': 'Распределение объектов',
    'ui.page.distribution.icon': 'document-text',
    'ui.page.multisign.title': 'Выбранные для подписания',
    'ui.page.multisign.icon': 'document-text',
};

/** Переопределение словаря вида {'ui.ent.user.title': 'Пользователь'} */
export type TermDict = { [key: string]: string };
@Injectable({
    providedIn: 'root',
})
export class TermService {
    currentOverride: TermDict = {}; // Это переопределение словаря, устанавливается в app.service при adjustRole this.termSe.currentOverride = role.override;

    /** Возвращает термин из словаря или из override */
    getTerm(code: string, override?: TermDict | string) {
        let term: string = null;
        if (override && typeof override === 'object') term = override[code];
        if (!term && this.currentOverride) term = this.currentOverride[code]; //this.currentOverride всегда truthy Если в currentOverride нет термина то undefined и след.пункт
        if (!term) term = TERM_DCT[code] || (typeof override === 'string' ? override : null);
        return term;
    }
}
